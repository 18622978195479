import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'
import ProductPage from '~/components/Product/Page'

const ProductTemplate = ({ data: { complete, half } }) => (
  <Page>
    <Metadata title={complete.name} description={complete.shortDescription} />
    <ProductPage product={complete} secondaryProduct={half} />
  </Page>
)

export default ProductTemplate

export const query = graphql`
  query BakewareSetQuery($locale: String) {
    complete: contentfulProduct(
      slug: { eq: "11pc-bakeware-set" }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
    half: contentfulProduct(
      slug: { eq: "5pc-bakeware-set" }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
  }
`

ProductTemplate.propTypes = {
  data: PropTypes.shape({
    complete: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string
    }),
    half: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string
    })
  })
}
